import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { AddCircleOutline, ArrowBack, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import './SnapshotDetail.scss';
import {format} from "date-fns";

const backendUrl: string = process.env.REACT_APP_BACKEND_URL as string;

interface IRowItem {
  id: number;
  address: string;
  balance: number;
}

interface ITablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

interface IHeadColumns {
  id: string;
  label: string;
}

const headColumns: IHeadColumns[] = [
  {
    id: 'id',
    label: 'ID'
  },
  {
    id: 'address',
    label: 'Address'
  },
  {
    id: 'balance',
    label: 'Balance'
  }
];

function TablePaginationActions(props: ITablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function SnapshotDetail() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const [snap, setSnap] = useState(null) as any;

  const [rowData, setRowData] = useState({
    items: [] as IRowItem[],
    totalCount: 0
  });

  useEffect(() => {
    fetchRecords();
    fetchSnapData(Number(params.id));
  }, [page, rowsPerPage]);

  function fetchSnapData(id: number) {
    const url = backendUrl + '/snapshot/';
    const jwt = sessionStorage.getItem('jwt');

    axios
        .get(`${url}${id}`, {
          headers: {
            Authorization: 'Bearer ' + jwt
          }
        })
        .then((res) => {
          setSnap(res.data.data.snapshotData);
        });
  }

  function fetchRecords() {
    const apiUrl = backendUrl + '/record/get-by-id';
    const jwt = sessionStorage.getItem('jwt');

    setLoader(true);
    const payload = {
      pagination: {
        page,
        itemsPerPage: rowsPerPage
      },
      snapshotId: params.id
    };
    axios
      .post(apiUrl, payload, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
      .then((res) => {
        console.log('RES ->', res);
        setLoader(false);
        setRowData({
          items: res.data.data.records,
          totalCount: res.data.data.count
        });
      })
      .catch((err) => {
        console.error('Error while fetching from back end: ', err);
        setLoader(false);
      });
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return (
    <div className="panel-page">
      <div className="actions">
        <Link className="back-btn" to="../snapshots">
          <ArrowBack className="back-btn-icon" />
          Back
        </Link>
        {snap &&
            <h1><b>{snap.name}</b> | {format(new Date(snap.completion_date), 'dd/MM/yyyy')}</h1>
      }
      </div>
      <div className="records-table">
        <TableContainer component={Paper}>
          {loader && <LinearProgress />}
          <Table sx={{ minWidth: 500 }} aria-label="snapshot records table">
            <TableHead>
              <TableRow>
                {headColumns.map((header) => (
                  <TableCell key={header.id}>{header.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.items.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.balance}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {!loader && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    colSpan={3}
                    count={rowData.totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page'
                      },
                      native: true
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
