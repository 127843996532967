import React, { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  LinearProgress,
  Paper,
  IconButton,
  Box
} from '@mui/material';
import './DataTable.scss';
import { format } from 'date-fns';

const backendUrl: string = process.env.REACT_APP_BACKEND_URL as string;

interface IRowItem {
  id: number;
  sourceBlockchain: string;
  sourceAddress: string;
  targetAddress: string;
  targetBlockchain: string;
  createdAt: Date;
  updatedAt: Date;
}

interface ITablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

interface IHeadColumns {
  id: string;
  label: string;
}

const headColumns: IHeadColumns[] = [
  {
    id: 'id',
    label: 'ID'
  },
  {
    id: 'sourceBlockchain',
    label: 'Source'
  },
  {
    id: 'sourceAddress',
    label: 'XRPL Address'
  },
  {
    id: 'targetBlockchain',
    label: 'Target'
  },
  {
    id: 'targetAddress',
    label: 'Songbird Address'
  },
  {
    id: 'updatedAt',
    label: 'Timestamp'
  }
];

function TablePaginationActions(props: ITablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CustomPaginationActionsTable() {
  const [page, setPage] = useState(0); // Start page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default "Rows per Page" selector
  const [loader, setLoader] = useState(false); // Show "spinner", disable buttons

  const [rowData, setRowData] = useState({
    items: [] as IRowItem[],
    totalCount: 0
  });

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  function fetchData() {
    const apiUrl = backendUrl + '/address-binding/search';

    const jwt = sessionStorage.getItem('jwt');

    setLoader(true);
    const payload = {
      pagination: {
        page,
        itemsPerPage: rowsPerPage
      }
    };
    axios
      .post(apiUrl, payload, {
        headers: {
          'Authorization': 'Bearer ' + jwt
        }
      })
      .then((res) => {
        console.log('Data: ', res.data.data.results);
        setLoader(false);

        setRowData({
          items: res.data.data.results,
          totalCount: res.data.data.count
        });
      })
      .catch((err) => {
        console.error('Error while fetching from backend: ', err);
        setLoader(false);
      });
  }

  function exportCsv() {
    const apiUrl = backendUrl + '/address-binding/export';
    const jwt = sessionStorage.getItem('jwt');
    setLoader(true);

    const payload = {
      pagination: {
        page: 0,
        itemsPerPage: 100000
      }
    };

    axios
      .post(apiUrl, payload, {
        headers: {
          'Authorization': 'Bearer ' + jwt
        }
      })
      .then((res) => {
        handleCsv(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.error('Error while downloading data: ', err);
      });
  }

  function handleCsv(data: string) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Bindings-${format(new Date(), 'yyyy_MM_dd')}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      {loader && <LinearProgress />}
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell className="no-borders strong">
            <Button
              variant="text"
              color="primary"
              startIcon={<FileDownloadIcon />}
              disabled={loader}
              onClick={exportCsv}
            >
              Export Data
            </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            {headColumns.map((header) => (
              <TableCell key={header.id} align={header.id !== 'updatedAt' ? 'left' : 'right'}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.items.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell>{row.sourceBlockchain}</TableCell>
              <TableCell>{row.sourceAddress}</TableCell>
              <TableCell>{row.targetBlockchain}</TableCell>
              <TableCell>{row.targetAddress}</TableCell>
              <TableCell align="right">{format(new Date(row.updatedAt), 'MMMM do, yyyy HH:mm')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {!loader && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 25, 50]}
                colSpan={3}
                count={rowData.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
