import React, { FormEvent, useState } from 'react';
import './SnapshotForm.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

export default function SnapshotForm() {
  enum Status {
    SCHEDULED = 'SCHEDULED',
    RUNNING = 'RUNNING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
  }

  const [name, setName] = useState('');
  const [schedule_date, setDate] = useState(format(new Date(), "yyyy-MM-dd'T'HH:mm"));
  const [status, setStatus] = useState(Status.SCHEDULED);

  const apiUrl = process.env.REACT_APP_BACKEND_URL + '/snapshot/schedule';
  const jwt = sessionStorage.getItem('jwt');

  const navigate = useNavigate();

  function sendData(e: FormEvent) {
    e.preventDefault();
    const payload = {
      name: name,
      schedule_date: schedule_date,
      status: status
    };
    axios
      .post(apiUrl, payload, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
      .then((r: any) => {
        console.log('Snapshot schedule result -> ', r.message);
        back();
      })
      .catch((e) => console.error('Error during insertion -> ', e));
  }

  function back() {
    navigate('/snapshots');
  }

  return (
    <>
      <div className="panel-page">
        <h1 className="schedule-form-title">New snapshot</h1>
        <form className="snap-form" onSubmit={(e) => sendData(e)}>
          <label>
            Name:
            <br />
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </label>
          <label>
            Schedule date:
            <br />
            <input
              type="datetime-local"
              value={schedule_date}
              onChange={(e) => setDate(format(new Date(e.target.value), "yyyy-MM-dd'T'HH:mm"))}
            />
          </label>
          <div className="btn-box">
            <input className="form-btn submit" type="submit" value="Schedule snapshot" />
            <input className="form-btn cancel" type="button" onClick={back} value="Cancel" />
          </div>
        </form>
      </div>
    </>
  );
}
