import React from 'react';
import './Bindings.scss';
import DataTable from './DataTable';

export default function BindingsPage() {
  return (
    <div className="panel-page">
      <DataTable />
    </div>
  );
}
