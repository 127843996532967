import React from 'react';
import SnapshotsList from './SnapshotsList';
import { AddCircleOutline } from '@mui/icons-material';
import './Snapshots.scss';
import { Link } from 'react-router-dom';

export default function SnapshotsPage() {
  return (
    <>
      <div className="panel-page">
        <div className="actions">
          <Link className="add-btn" to="schedule">
            <AddCircleOutline className="add-icon" />
            Schedule snapshot
          </Link>
        </div>
        <div className="snap-table">
          <SnapshotsList />
        </div>
      </div>
    </>
  );
}
