import React from 'react';
import './Topbar.scss';
import { MdSettings } from 'react-icons/md';

export default function Topbar() {
  return (
    <div className="topbar">
      <div className="topbar-wrapper">
        <div className="left-content">
          <span className="logo">elysian-athena</span>
        </div>
        <div className="right-content">
          <div className="icon-container">
            <span className="icon">
              <MdSettings />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
