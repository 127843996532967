import React, { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  LinearProgress,
  Paper,
  IconButton,
  Box,
  Collapse
} from '@mui/material';
import './SnapshotsList.scss';
import { format } from 'date-fns';

const backendUrl: string = process.env.REACT_APP_BACKEND_URL as string;

interface IRowItem {
  id: number;
  schedule_date: Date;
  completion_date: Date;
  status: string;
  name: string;
  addresses_amount: number;
  token_amount: number;
  average_tokens_per_account: number;
  notarization_hash: string;
  notarization_link: string;
  createdAt: Date;
  updatedAt: Date;
  open?: boolean;
}

interface ITablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

interface IHeadColumns {
  id: string;
  label: string;
}

const headColumns: IHeadColumns[] = [
  {
    id: 'id',
    label: 'ID'
  },
  {
    id: 'schedule_date',
    label: 'Schedule date'
  },
  {
    id: 'completion_date',
    label: 'Completion Date'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'addresses_amount',
    label: 'Addresses amount'
  },
  {
    id: 'token_amount',
    label: 'Token Amount'
  },
  {
    id: 'average_tokens_per_account',
    label: 'Average tokens per account'
  },
  {
    id: 'download',
    label: ''
  }
];

const detailRowHeaders: IHeadColumns[] = [
  {
    id: 'notarization_hash',
    label: 'Notarization Hash'
  },
  {
    id: 'notarization_link',
    label: 'Notarization Link'
  },
  {
    id: 'detail',
    label: ''
  },
  {
    id: 'delete',
    label: ''
  }
];

function TablePaginationActions(props: ITablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CustomPaginationActionsTable() {
  const [page, setPage] = useState(0); // Start page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default "Rows per Page" selector
  const [loader, setLoader] = useState(false); // Show "spinner", disable buttons
  const [open, setOpen] = useState(false); // handle detail row expand and collapse
  const [selectedRow, selectRow] = useState(0);

  const [rowData, setRowData] = useState({
    items: [] as IRowItem[],
    totalCount: 0
  });

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  function fetchData() {
    const apiUrl = backendUrl + '/snapshot/search';

    const jwt = sessionStorage.getItem('jwt');

    setLoader(true);
    const payload = {
      pagination: {
        page,
        itemsPerPage: rowsPerPage
      }
    };
    axios
      .post(apiUrl, payload, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
      .then((res) => {
        console.log('Data: ', res.data.data.results);

        res.data.data.results.forEach((item: IRowItem) => {
          item.open = false;
        });

        setLoader(false);

        setRowData({
          items: res.data.data.results,
          totalCount: res.data.data.count
        });
      })
      .catch((err) => {
        console.error('Error while fetching from backend: ', err);
        setLoader(false);
      });
  }

  function handleDetailRow(id: number) {
    if (selectedRow != id && open) {
      setOpen(open);
    } else {
      setOpen(!open);
    }
    selectRow(id);
  }

  function handleCsv(data: string) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Snapshot-${format(new Date(), 'yyyy_MM_dd')}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  function exportCsv(id: number) {
    const apiUrl = backendUrl + '/snapshot/export/' + id;
    const jwt = sessionStorage.getItem('jwt');
    setLoader(true);

    const payload = {
      pagination: {
        page: 0,
        itemsPerPage: 100000
      }
    };

    axios
      .get(apiUrl, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
      .then((res) => {
        handleCsv(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.error('Error while downloading data: ', err);
      });
  }

  function deleteSnapshot(id: number) {
    const jwt = sessionStorage.getItem('jwt');
    const apiUrl = backendUrl + '/snapshot/remove/' + id;
    setLoader(true);

    axios
      .delete(apiUrl, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
      .then(() => fetchData())
      .catch((e) => console.error(e))
      .finally(() => setLoader(false));
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      {loader && <LinearProgress />}
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell />
            {headColumns.map((header) => (
              <TableCell key={header.id} align={header.id !== 'updatedAt' ? 'left' : 'right'}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.items.map((row) => (
            <>
              <TableRow key={row.id}>
                <TableCell>
                  <IconButton aria-label="expand row" size="small" onClick={() => handleDetailRow(row.id)}>
                    {open && selectedRow == row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{format(new Date(row.schedule_date), 'MMMM do, yyyy HH:mm')}</TableCell>
                <TableCell>
                  {row.completion_date ? format(new Date(row.completion_date), 'MMMM do, yyyy HH:mm') : ''}
                </TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.addresses_amount}</TableCell>
                <TableCell>{row.token_amount}</TableCell>
                <TableCell>{row.average_tokens_per_account}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<FileDownloadIcon />}
                    disabled={loader}
                    onClick={() => exportCsv(row.id)}
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={open && selectedRow == row.id} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {detailRowHeaders.map((head) => (
                              <TableCell key={head.id}>{head.label}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{row.notarization_hash}</TableCell>
                            <TableCell>
                              <a target="_blank" rel="noreferrer" href={row.notarization_link}>View transaction</a>
                            </TableCell>
                            <TableCell>
                              <Link className="view-btn" to={'view/' + row.id}>
                                Detail
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="error"
                                disabled={row.status != 'SCHEDULED'}
                                onClick={(event) => {
                                  event.preventDefault();
                                  deleteSnapshot(row.id);
                                }}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {!loader && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 25, 50]}
                colSpan={3}
                count={rowData.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
