import React from 'react';
import './Sidebar.scss';
import {MdList, MdTimeline} from 'react-icons/md';
import {SidebarLink} from './SidebarLink';

export default function SideBar() {
  return (
    <div className="sidebar">
      <div className="sidebar-wrapper">
        <div className="menu">
          <h3 className="title">Main Panel</h3>
          <div className="list">
            <SidebarLink to="/bindings">
              <MdTimeline className="sidebar-icon" />
              Bindings
            </SidebarLink>
            <SidebarLink to="/snapshots">
              <MdList className="sidebar-icon" />
              Snapshots
            </SidebarLink>
          </div>
        </div>
      </div>
    </div>
  );
}
