import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthProps } from '../../security/protected-route';
import './Login.scss';
// import { AuthProps } from '../../security/protected-route'

const backendUrl: string = process.env.REACT_APP_BACKEND_URL as string;

async function loginUser(credentials: { email: string; password: string }) {
  const response = await fetch(`${backendUrl}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  });
  const json = await response.json();
  if (response.status !== 200) {
    if (json && json.message) {
      throw json.message;
    }
    throw response.text;
  }
  return json;
}

type LoginPageProps = {
  authProps: AuthProps;
  setAuthProps: (a: AuthProps) => void;
};

/* eslint-disable react/prop-types */
export default function LoginPage(props: LoginPageProps) {
  const { authProps, setAuthProps } = props;
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const loginData = await loginUser({ email, password });
      console.log('Login data: ', loginData);
      setAuthProps({
        ...authProps,
        isAuthenticated: true //!!sessionContext.isAuthenticated,
      });
      sessionStorage.setItem('jwt', loginData.data.token);
      navigate('/');
    } catch (exc) {
      alert('Could not login: ' + exc);
    }
  };

  return (
    <>
      <div className="loginpanel">
        <div className="logopanel">
          <img className="logo" src="/logo.svg" />
          <span className="txt">Elysian-Athena Admin Console</span>
        </div>
        <div className="txt">
          <input id="email" type="text" placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} />
          <label htmlFor="email" className="entypo-user"></label>
        </div>
        <div className="txt">
          <input id="pwd" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
          <label htmlFor="pwd" className="entypo-lock"></label>
        </div>
        <div className="buttons">
          <input type="button" value="Login" onClick={handleLogin} />
        </div>
      </div>

      <span className="resp-info"></span>
    </>
  );
}
