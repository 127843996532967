import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AuthProps, ProtectedRoute } from './security/protected-route';
import LoginPage from './pages/login/Login';
import BindingsPage from './pages/bindings/Bindings';
import SnapshotsPage from './pages/snapshots/Snapshots';
import SideBar from './components/sidebar/Sidebar';
import TopBar from './components/topbar/Topbar';
import SnapshotForm from './pages/snapshot-add/SnapshotForm';
import SnapshotDetail from './pages/snapshots/SnapshotDetail';

const AppRoutes = () => {
  const jwt = sessionStorage.getItem('jwt');
  const defaultProtectedRouteProps: AuthProps = {
    isAuthenticated: !!jwt,
    authenticationPath: '/login'
  };

  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  const [authProps, setAuthProps] = useState(defaultProtectedRouteProps);

  return (
    <>
      {authProps.isAuthenticated && (
        <>
          <TopBar />
          <SideBar />
        </>
      )}
      <Routes>
        <Route path="/login" element={<LoginPage authProps={authProps} setAuthProps={setAuthProps} />} />
        <Route path="*" element={<ProtectedRoute {...authProps} outlet={<BindingsPage />} />} />
        <Route path="/snapshots" element={<ProtectedRoute {...authProps} outlet={<SnapshotsPage />} />} />
        <Route path="/snapshots/schedule" element={<ProtectedRoute {...authProps} outlet={<SnapshotForm />} />} />
        <Route path="/snapshots/view/:id" element={<ProtectedRoute {...authProps} outlet={<SnapshotDetail />} />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
